import { Component } from "@angular/core";

@Component({
    selector: 'loading',
    template: `
        <div class="root-initial-loader">
            <i class= "fas fa-circle-notch fa-3x fa-spin-2x"></i>
        </div>
    `,
})
export class LoadingComponent { }
