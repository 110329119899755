<div class="topbar-item">
    <a href="javascript:;" [class]="togglerCssClass" id="kt_quick_user_toggle" data-toggle="tooltip"
        data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Profile">
        <span class="mobile-profile-icon symbol symbol-30 symbol-lg-40 d-none">
            <span class="svg-icon svg-icon-xl">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                        <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="#000000" fill-rule="nonzero"></path>
                    </g>
                </svg>
            </span>
        </span>

        <span class="profile-icon" *ngIf="!isMobileDevice()">
            <i *ngIf="isImpersonatedLogin" class="fa fa-reply text-danger"></i>
            <span [class]="textCssClass">
                <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
            </span>
        </span>
    </a>
</div>

<div id="kt_quick_user" [perfectScrollbar]="{wheelPropagation: true, suppressScrollX: true}"
    class="offcanvas offcanvas-right p-10" ktOffcanvas [options]="offcanvasOptions">
    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
            <div class="symbol symbol-100 mr-5">
                <div class="symbol-label">
                    <img [src]="profilePicture" class="symbol-label" />
                </div>
            </div>
            <div class="d-flex flex-column">
                <a href="javascript:;"
                    class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary topbar-username">
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
                </a>
                <div class="navi mt-2">
                    <a href="javascript:;" (click)="logout()"
                        class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">
                        {{"Logout" | localize}}
                    </a>
                </div>
            </div>
        </div>
        <!--end::Header-->

        <!--begin::Separator-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--end::Separator-->

        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
            <a href="javascript:;" *ngIf="isImpersonatedLogin" (click)="backToMyAccount()" class="navi-item"
                id="UserProfileBackToMyAccountButton">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-danger">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M21.4451171,17.7910156 C21.4451171,16.9707031 21.6208984,13.7333984 19.0671874,11.1650391 C17.3484374,9.43652344 14.7761718,9.13671875 11.6999999,9 L11.6999999,4.69307548 C11.6999999,4.27886191 11.3642135,3.94307548 10.9499999,3.94307548 C10.7636897,3.94307548 10.584049,4.01242035 10.4460626,4.13760526 L3.30599678,10.6152626 C2.99921905,10.8935795 2.976147,11.3678924 3.2544639,11.6746702 C3.26907199,11.6907721 3.28437331,11.7062312 3.30032452,11.7210037 L10.4403903,18.333467 C10.7442966,18.6149166 11.2188212,18.596712 11.5002708,18.2928057 C11.628669,18.1541628 11.6999999,17.9721616 11.6999999,17.7831961 L11.6999999,13.5 C13.6531249,13.5537109 15.0443703,13.6779456 16.3083984,14.0800781 C18.1284272,14.6590944 19.5349747,16.3018455 20.5280411,19.0083314 L20.5280247,19.0083374 C20.6363903,19.3036749 20.9175496,19.5 21.2321404,19.5 L21.4499999,19.5 C21.4499999,19.0068359 21.4451171,18.2255859 21.4451171,17.7910156 Z"
                                            fill="#000000" fill-rule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="navi-text">
                    <div class="font-weight-bold">
                        {{"BackToMyAccount" | localize}}
                    </div>
                    <div class="text-muted">
                        {{"BackToMyAccount_Description" | localize}}
                    </div>
                </div>
            </a>
            <a href="javascript:;" (click)="showLinkedAccounts()" class="navi-item linked-accounts"
                id="ManageLinkedAccountsLink">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <path
                                            d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                        <path
                                            d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                            fill="#000000" fill-rule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{"ManageLinkedAccounts" | localize}}
                        </div>
                        <div class="text-muted">
                            {{"ManageLinkedAccounts_Description" | localize}}
                        </div>
                    </div>
                </div>
            </a>
            <div class="navi-item py-0 linked-accounts" *ngIf="recentlyLinkedUsers && recentlyLinkedUsers.length">
                <ul id="RecentlyUsedLinkedUsers" class="navi-text linked-accounts"
                    [attr.aria-label]="l('LinkedAccounts')" role="menu">
                    <li *ngFor="let linkedUser of recentlyLinkedUsers">
                        <a href="javascript:;" class="recently-linked-user" (click)="switchToLinkedUser(linkedUser)">
                            <span class="nav-link">
                                <span></span>
                            </span>
                            <span class="nav-link-text">
                                {{getShownUserName(linkedUser)}}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <a href="javascript:;" class="navi-item" id="ManageUserDelegations"
                *ngIf="appSession.application.userDelegationIsEnabled && !isImpersonatedLogin"
                (click)="showUserDelegations()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-warning">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                            fill="#000000" opacity="0.3" />
                                        <path
                                            d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                                            fill="#000000" opacity="0.3" />
                                        <path
                                            d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                            fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{"ManageUserDelegations" | localize}}
                        </div>
                        <div class="text-muted">
                            {{"ManageUserDelegations_Description" | localize}}
                        </div>
                    </div>
                </div>
            </a>
            <a href="javascript:;" class="navi-item" id="UserProfileChangePasswordLink" (click)="changePassword()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <circle fill="#000000" cx="5" cy="12" r="2" />
                                        <circle fill="#000000" cx="12" cy="12" r="2" />
                                        <circle fill="#000000" cx="19" cy="12" r="2" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{"ChangePassword" | localize}}
                        </div>
                        <div class="text-muted">
                            {{"ChangePassword_Description" | localize}}
                        </div>
                    </div>
                </div>
            </a>
            <a href="javascript:;" class="navi-item" id="ShowLoginAttemptsLink" (click)="showLoginAttempts()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-danger">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                            fill="#000000" opacity="0.3" />
                                        <path
                                            d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                            fill="#000000" />
                                        <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1" />
                                        <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1" />
                                        <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1" />
                                        <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1" />
                                        <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1" />
                                        <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{"LoginAttempts" | localize}}
                        </div>
                        <div class="text-muted">
                            {{"LoginAttempts_Description" | localize}}
                        </div>
                    </div>
                </div>
            </a>
            <a href="javascript:;" class="navi-item" id="UserProfileChangePictureLink" (click)="changeProfilePicture()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-info">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <rect fill="#000000" opacity="0.3" x="2" y="4" width="20" height="16" rx="2" />
                                        <polygon fill="#000000" opacity="0.3" points="4 20 10.5 11 17 20" />
                                        <polygon fill="#000000" points="11 20 15.5 14 20 20" />
                                        <circle fill="#000000" opacity="0.3" cx="18.5" cy="8.5" r="1.5" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{"ChangeProfilePicture" | localize}}
                        </div>
                        <div class="text-muted">
                            {{"ChangeProfilePicture_Description" | localize}}
                        </div>
                    </div>
                </div>
            </a>
            <a href="javascript:;" class="navi-item" id="UserProfileMySettingsLink" (click)="changeMySettings()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                                            fill="#000000" />
                                        <path
                                            d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                                            fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{"MySettings" | localize}}
                        </div>
                        <div class="text-muted">
                            {{"MySettings_Description" | localize}}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
