<div id="kt_header_mobile"
     class="header-mobile bg-primary  header-mobile-fixed ">
    <a routerLink="/"
       target="_blank">
        <img *ngIf="!appSession.tenant || !appSession.tenant.logoId"
             [src]="defaultLogo"
             alt="logo"
             height="28" />
        <img *ngIf="appSession.tenant && appSession.tenant.logoId"
             [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId"
             alt="logo"
             height="60" />
    </a>
    <div class="d-flex align-items-center">
        <button class="btn p-0 burger-icon burger-icon-left ml-4 mobile-burger-btn"
                id="kt_header_mobile_toggle">
            <span></span>
        </button>
        <button ktToggle
                [options]="userMenuToggleOptions"
                class="btn p-0 ml-2 mobile-person-btn"
                id="kt_header_mobile_topbar_toggle">
            <span class="svg-icon svg-icon-xl">
                <svg xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     width="24px"
                     height="24px"
                     viewBox="0 0 24 24"
                     version="1.1">
                    <g stroke="none"
                       stroke-width="1"
                       fill="none"
                       fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"></path>
                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                              fill="#000000"
                              fill-rule="nonzero"></path>
                    </g>
                </svg>
            </span>
        </button>
    </div>
</div>

<div [@routerTransition]
     class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper"
             id="kt_wrapper">
            <div id="kt_header"
                 class="header flex-column  header-fixed">
                <div class="header-top">
                    <div [class]="containerClass">
                        <div class="d-flex">
                            <div class="d-none d-lg-flex align-items-center mr-3">
                                <theme7-brand></theme7-brand>
                            </div>
                            <div class="topbar"
                                 style="align-items: center;">
                                <div>
                                    <top-bar-menu></top-bar-menu>
                                </div>
                            </div>
                        </div>

                        <div class="topbar">
                            <div class="topbar-item subscription-warning d-print-none"
                                 *ngIf="!isMobileDevice() && subscriptionStatusBarVisible()">
                                <subscription-notification-bar></subscription-notification-bar>
                            </div>
                            <active-delegated-users-combo [customStyle]="'btn btn-hover-transparent-white btn-lg mr-1'">
                            </active-delegated-users-combo>
                            <quick-theme-selection
                                                   [customStyle]="'btn btn-icon btn-hover-transparent-white btn-lg mr-1'">
                            </quick-theme-selection>

                            <!-- TODO: language menu element dont need now -->
                            <!-- <language-switch-dropdown
                                [customStyle]="'btn btn-icon btn-hover-transparent-white btn-lg mr-1'">
                            </language-switch-dropdown> -->

                            <header-notifications style="z-index: 3;"
                                                  [customStyle]="'btn btn-icon btn-hover-transparent-white btn-lg mr-1'">
                            </header-notifications>
                            <user-menu [togglerCssClass]=""
                                       style="z-index: 3;">
                            </user-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-bottom" id="kt_header_bottom">
                <div [class]="containerClass + ' d-flex flex-column'">
                    <div class="header-navs header-navs-left flex-column-fluid" id="kt_header_navs">
                        <div class="tab-content pb-5 pt-5">
                            <div class="tab-pane show active" id="kt_header_tab_1">
                                <top-bar-menu></top-bar-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-subscription-warning d-print-none mt-3"
                 *ngIf="isMobileDevice() && subscriptionStatusBarVisible()">
                <subscription-notification-bar></subscription-notification-bar>
            </div>
            <div style="flex:1">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
